html,
body,
#root {
	width: 100%;
	height: 100%;
	margin: 0px;
	background-color: black;
}

@font-face {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif, monospace;
	src: url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }


body {
	overflow: hidden; 
	position: fixed;
	margin: 0;
	padding: 0;
	/* font-family: 'Orbitron'; */
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif, monospace;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif, monospace;
}

.game {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

